$fontColor: rgba(0, 0, 0, 0.87);

@for $i from 0 through 50 {
  .mt#{$i * 5} {
    margin-top:#{$i * 5}px;
  }
}

@for $i from 0 through 50 {
  .mb#{$i * 5} {
    margin-bottom:#{$i * 5}px;
  }
}

html, body {
  background-color: #eaeff1;
}

#overlay-progress {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba($color: #fff, $alpha: 0.5);
  top: 0;
  left: 0;
  #overlay-progress-in {
    position: absolute;
    width: 40px;
    height: 43px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
}
.error-message {
  font-weight: bold;
  color: #d32f2f;
}
.auth-loading {
  background-color: #eaeff1;
  position: relative;
}
.auth-loading > div {
  opacity: 0;
}
.auth-loading-progress {
  position: absolute;
  width: 40px;
  height: 43px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.red {
  color: #d32f2f;
}
.bold {
  font-weight: bold;
}

/**
 * Header
 */
#header-auth-informations {
  min-width: 150px;
  #Hai-authority-display {
    margin-right: 10px;
    user-select: none;
  }
  #Hai-fullname {
    user-select: none;
  }
  #Hai-showMore {
    margin-top: -2px;
  }
  #header-auth-informations-in {
    position: relative;
  }
  #header-auth-informations-display {
    display: flex;
    cursor: pointer;
    height: 20px;
    justify-content: flex-end;
  }
  #header-auth-informations-more {
    transition: all 0.3s ease;
    position: absolute;
    background-color: #fff;
    color: $fontColor;
    width: 100%;
    left: 0;
    top: 25px;
    font-size: 13px;
    border-radius: 4px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
    ul {
      padding: 5px 0;
      margin: 0;
      list-style: none;
      li {
        &:last-child {
          border-top: 1px solid rgba(0, 0, 0, 0.14);
          padding-top: 5px;
          margin-top: 5px;
        }
        a, a:visited {
          color: $fontColor;
          text-decoration: none;
        }
        a, span {
          cursor: pointer;
          display: block;
          padding: 5px 35px 5px 15px;
          &:hover {
            background-color: #EEE;
          }
        }
      }
    }
  }
  .hideMore {
    display: none;
    opacity: 0;
  }
  .showMore {
    display: block;
    opacity: 1;
  }
}
.header-auth-edit-password {
  width: 400px;
  max-width: 100%;
}

/**
* Side Menu
*/
.menuTitleNone {
  padding-top: 15px!important;
  padding-bottom: 0!important;
}
.sideLink {
  text-decoration: none;
}

/**
* h
*/
.h-1 {
  font-weight: normal;
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0;
}
.h-2 {
  margin-bottom: 0;
  margin-top: 0;
}
.h-3 {
  margin-top: 35px;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 1rem;
}
@media screen and (max-width:768px) {
  .h-1 {
    font-size: 1.1rem;
  }
}

/**
 * ul
 */
.ul-1 {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}
  
/**
* Table
*/
.data-table {
  width: 100%;
}
.data-table-nch {
  .MuiTableCell-root {
    padding: 9px;
  }
}
.table-1 {
  width: 100%;
  th, td {
    padding: 10px;
    text-align: left;
    vertical-align: top;
    border-bottom: 1px solid #EEE;
    font-size: 13px;
  }
  th {
    width: 150px;
  }
  td {
    word-break: break-all;
  }
  a, a:visited {
    text-decoration: none;
    color: #0486c3;
  }
  a:hover {
    text-decoration: underline;
  }
}
.data-table {
  table {
    .data-table-sp-label {
      display: none;
    }
  }
  .data-table-head-info {
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: rgba(0, 0, 0, 0.54);
    margin-top: 15px;
    margin-bottom: 5px;
    .dt-hi-start {
      padding-left: 10px;
      padding-right: 5px;
    }
    .dt-hi-end {
      padding-left: 5px;
    }
  }
  .data-table-head-loading-row {
    height: 5px;
  }
  .data-table-inline-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .data-table-inline-content-text {
      display: flex;
      align-content: center;
      div {
        display: inline-flex;
        padding-right: 10px;
      }
      i {
        margin-right: 2px;
      }
    }
  }
  .search-informatons {
    padding-top: 15px;
    .search-informatons-wr {
      display: flex;
    }
    .search-informaton {
      display: flex;
      margin-right: 15px;
      .search-informaton-title {
        font-weight: bold;
        &::after {
          content: ":";
          padding-right: 5px;
        }
      }
    }
  }
  .datatable-params {
    padding-top: 15px;
    .datatable-params-wr {
      display: flex;
      flex-wrap: wrap;
    }
    .datatable-param {
      display: flex;
      margin-right: 15px;
      align-items: center;
      .datatable-param-title {
        font-weight: bold;
        padding-top: 1px;
        &::after {
          content: ":";
        }
      }
      .datatable-param-info {
        padding-left: 5px;
      }
      .MuiButton-text {
        min-width: auto;
        padding: 6px 5px;
      }
    }
  }
}
.datatable-params-dialog-input-wr {
  padding-bottom: 15px;
  &::last-child {
    padding-bottom: 0;
  }
}
@media screen and (max-width:768px) {
  .table-1 th, .table-1 td {
    display: block;
    width: 100%;
  }
  .table-1 th {
    border-bottom: none;
    padding-bottom: 5px;
  }
  .data-table {
    max-width: 100%;
    table {
      thead {
        display: block;
        tr {
          display: block;
          height: 40px;
        }
        th {
          display: none;
          border: none;
          height: 40px;
        }
        .data-table-checkAll {
          display: block;
        }
        .data-table-head-loading {
          display: block;
        }
      }
      tbody {
        tr {
          display: block;
          height: auto;
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          td {
            width: 100%;
            display: block;
            border: none;
            padding-top: 10px;
            &:last-child {
              padding-bottom: 15px;
            }
          }
        }
      }
      .data-table-sp-label {
        display: inline-block;
        &:after {
          content: ':';
          padding-right: 5px;
          padding-left: 5px;
        }
      }
    }
    .data-table-inline-content {
      .data-table-inline-content-text {
        display: block;
      }
    }
    .data-table-tbody-td {
      padding: 10px!important;
    }
  }
}
  
/**
* View
*/
.content-1 {
  font-size: 13px;
}
.content-2 {
  margin-top: 25px;
  padding: 15px;
  font-size: 13px;
}
.btn-group {
  padding: 10px 0;
  .btn {
    margin-right: 10px;
  }
}
.view-content-in {
  padding: 15px;
}
.search-input {
  display: inline-block;
}
.search-filter {
  display: inline-block;
}
.index-btn-group {
  display: block;
  margin-right: 5px;
  button {
    margin-right: 10px;
    margin-bottom: 15px;
  }
}
.zshTooltip {
  display: inline-block;
  padding-left: 5px;
  vertical-align: middle;
}
.filter-groups-inline {
  .index-btn-group {
    display: inline-block;
    button {
      margin-bottom: 0;
    }
  }
}
.error-view {
  p {
    padding-bottom: 15px;
    font-size: 1.1rem;
  }
}
.zsh-logs {
  li {
    cursor: pointer;
    &:hover {
      background-color: #EEE;
    }
  }
}

/**
 * Form
 */
.form-input-group {
  padding-bottom: 10px;
  .form-label-title {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
  }
  .zipcode-input {
    width: 100px;
  }
  .zipcode-hyphen {
    display: inline-flex;
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .zipcode-btn {
    margin-left: 15px;
  }
  .prefecture {
    min-width: 120px;
  }
  .zsh-select-root,
  .attribute-group-select-root {
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .zsh-select {
    min-width: 120px;
  }
  .zsh-editor {
    min-height: 200px;
  }
}
.zipcode-input-group {
  padding-bottom: 15px;
}
.dialog-form {
  .form-input-group {
    padding-bottom: 25px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .form-textarea {
    margin-top: 0;
  }
}
.roles-ul {
  min-width: 100px;
}
.personal-role-select {
  font-size: 14px!important;
}
.radio-form-control-1 {
  margin-top: 20px!important;
}
.form-label-1 {
  font-size: 0.8rem!important;
}
.editor-title {
  font-size: 0.8rem!important;
  margin-bottom: 10px;
}
.input-er-msg {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  display: block;
}
.form-file-content {
  .form-input-group {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
.attribute-group-select-title {
  font-size: 0.8rem!important;
}
.attribute-group-select-toggle-btn {
  padding-top: 10px;
  display: flex;
  button {
    height: 35px;
  }
  .attribute-group-select-selected-name {
    padding-left: 15px;
  }
}
.attribute-group-select-content {
  width: 500px;
  max-width: 100%;
}
.attribute-group-select-selected-name {
  padding-left: 10px;
}
.attribute-group-select-root-er {
  .attribute-group-select-title {
    color: #f44336;
  }
}
.form-table {
  width: 100%;
  tbody {
    th, td {
      padding: 10px 0;
    }
    th {
      width: 150px;
      text-align: left;
      vertical-align: top;
    }
    .required-th:after {
      color: #f44336;
      content: "*";
      padding-left: 5px;
    }
  }
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
  .zsh-select-root,
  .attribute-group-select-root {
    margin-top: 0;
    margin-bottom: 0;
  }
  .radio-form-control-1 {
    margin-top: 0!important;
  }
  .zipcode-hyphen {
    margin-top: 20px;
  }
  .attribute-group-select-toggle-btn {
    padding-top: 0;
  }
  .zsh-datetime-field {
    .zsh-datetime-clear-btn {
      margin-left: 10px;
    }
  }
  .zsh-input-btn {
    margin-left: 10px;
  }
}

@media screen and (max-width:768px) {
  .form-input-group {
    .form-input {
      width: 100%;
    }
    .zipcode-input {
      width: 80px;
    }
  }
  .form-table {
    tbody {
      th, td {
        display: block;
      }
    }
  }
}

/**
 * SearchFilter
 */
.search-filter-group {
  padding-bottom: 30px;
}